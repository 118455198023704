<template>
  <div v-if="field" class="field-container" :class="field.type + '-container'">
    <input v-if="field.type == 'text'" type="text" :name="field.key" :placeholder="field.placeholder"
      :class="field.fieldClass" :autocomplete="field.autocomplete" :disabled="disabled || field.disabled"
      v-model="value" />
    <input v-else-if="field.type == 'number'" :name="field.key" type="number" inputmode="numeric"
      :disabled="disabled || field.disabled" v-model="value" />
    <input v-else-if="field.type == 'tel'" :name="field.key" type="tel" inputmode="tel"
      :disabled="disabled || field.disabled" v-model="value" :placeholder="field.placeholder"/>
    <textarea v-else-if="field.type == 'textarea'" :name="field.key" v-model="value" :disabled="disabled"
      @keyup="(e) => textAreaAdjust(e)" @blur="(e) => textAreaAdjust(e)" @click="(e) => textAreaAdjust(e)"></textarea>
    <div v-else-if="field.type == 'radio'" class="custom-radio-container">
      <vs-radio v-for="option in field.options" :key="option.val" v-model="value" :vs-name="field.key"
        :vs-value="option.val" @change="radioChange">{{ option.label }}</vs-radio>
    </div>
    <input v-else-if="field.type == 'email'" type="email" inputmode="email" :name="field.key"
      :placeholder="field.placeholder" :class="field.fieldClass" :disabled="disabled || field.disabled"
      v-model="value" />
    <input v-else-if="field.type == 'password'" type="password" :name="field.key" :placeholder="field.placeholder"
      :class="field.fieldClass" :disabled="disabled || field.disabled" v-model="value" />
    <vs-checkbox class="checkbox-container" v-else-if="field.type == 'checkbox'" v-model="value"></vs-checkbox>
    <CustomFileUpdate v-else-if="field.type == 'upload'" v-model="value" :field="field" :disabled="disabled" />
    <select v-else-if="field.type == 'select' && field.optionsValues" v-model="value" :disabled="disabled">
      <option v-for="option in field.optionsValues" :key="option.val" :value="option.val"> {{ option.label }}
      </option>
    </select>
    <CustomAutoComplete v-else-if="field.type == 'autocomplete'" class="autocomplete" :listKey="field.key"
      v-model="value" :disabled="disabled" :options="field.options">
    </CustomAutoComplete>
    <input v-else-if="field.type == 'date'" type="date" :id="field.key" aria-describedby="date-format"
      :name="field.name ?? field.key" v-model="value" :min="field.minDate" :max="field.maxDate" />
    <FbPageSelector v-else-if="field.type == 'fbpage-selector'" v-model="value" v-bind="field.attributes">
    </FbPageSelector>
    <FbIgUserSelector v-else-if="field.type == 'fbiguser-selector'" v-model="value" v-bind="field.attributes">
    </FbIgUserSelector>
    <FbWaBASelector v-else-if="field.type == 'fbwaba-selector'" v-model="value" v-bind="field.attributes">
    </FbWaBASelector>
    <ChannelSelector v-else-if="field.type == 'channel-selector'" v-model="value" v-bind="field.attributes">
    </ChannelSelector>
    <AgentSelector v-else-if="field.type == 'agent-selector'" v-model="value" v-bind="field.attributes"></AgentSelector>
    <FbWaPhoneSelector v-else-if="field.type == 'fbwaphone-selector'" v-model="value" v-bind="field.attributes">
    </FbWaPhoneSelector>
    <WaWebPhoneSelector v-else-if="field.type == 'wawebphone-selector'" v-model="value" v-bind="field.attributes">
    </WaWebPhoneSelector>
    <WaWebLabelSelector v-else-if="field.type == 'waweblabel-selector'" v-model="value" v-bind="field.attributes">
    </WaWebLabelSelector>
    <OrganisationSelector v-else-if="field.type == 'organisation-selector'" v-model="value" v-bind="field.attributes">
    </OrganisationSelector>
    <FbApplicationSelector v-else-if="field.type == 'fbapplication-selector'" v-model="value" v-bind="field.attributes">
    </FbApplicationSelector>
    <SocialContentLabelOptionSelector v-else-if="field.type == 'social-content-label-option-selector'" v-model="value"
      v-bind="field.attributes" @additionalData="getAdditionalData">
    </SocialContentLabelOptionSelector>
    <OrganisationQuickReplySelector v-else-if="field.type == 'organisation-quick-reply-selector'" v-model="value"
      v-bind="field.attributes">
    </OrganisationQuickReplySelector>
    <FbWaBaTemplateSelector class="fb-waba-template-selector" v-else-if="field.type == 'fb-waba-template-selector'" v-model="value"
      v-bind="field.attributes">
    </FbWaBaTemplateSelector>
    <div v-else-if="field.type == 'input-group'">
      <CustomFormInputGroup v-if="isArrayValue() != true" :fields="field.fields" v-model="value"
        @changeFieldDisplay="(val) => updateFieldDisplay(val)">
      </CustomFormInputGroup>
      <div v-else>
        <div v-for="(val,i) in this.value" :key="i" class="sub-form-item-array-container">
          <label>{{ i + 1 }}</label>
          <CustomFormInputGroup :fields="field.fields" :modelValue="val"
            @update:modelValue="(d) => { updateSubItemValue(d,i) }"
            @changeFieldDisplay="(d) => { updateFieldDisplay(d) }"></CustomFormInputGroup>
        </div>
      </div>
    </div>


    <VueDatePicker v-else-if="field.type == 'date-range'" v-model="value" :range="field.range" />
    <span v-else-if="field.type == 'display-data'">
      {{ field.dataFormatter ? field.dataFormatter(value) : value }}
    </span>
    <div v-else>
      <slot :name="field.type"></slot>
    </div> 
  </div>
</template>

<script>
import CustomAutoComplete from './CustomAutoComplete.vue';
import FbPageSelector from './formItems/FbPageSelector.vue';
import FbIgUserSelector from './formItems/FbIgUserSelector.vue';
import FbWaBASelector from './formItems/FbWaBaSelector.vue';
import AgentSelector from './formItems/AgentSelector.vue';
import ChannelSelector from './formItems/ChannelSelector.vue';
import WaWebLabelSelector from './formItems/WaWebLabelSelector.vue';
import WaWebPhoneSelector from './formItems/WaWebPhoneSelector.vue';
import FbWaPhoneSelector from './formItems/FbWaPhoneSelector.vue';

import OrganisationSelector from './formItems/OrganisationSelector.vue';
import OrganisationQuickReplySelector from './formItems/OrganisationQuickReplySelector.vue';
import FbWaBaTemplateSelector from './formItems/FbWaBaTemplateSelector.vue';

import FbApplicationSelector from './formItems/FbApplicationSelector.vue';
import SocialContentLabelOptionSelector from './formItems/SocialContentLabelOptionSelector.vue';
import CustomFileUpdate from './formItems/CustomFileUpdate.vue';
import CustomFormInputGroup from './CustomFormInputGroup.vue';

export default {
  name: "CustomFormItemCmp",
  props: {
    field: { type: Object,default: () => { } },
    modelValue: { type: [String,Number,Boolean,Object] },
    disabled: { type: Boolean,default: () => false },
  },
  components: {
    CustomAutoComplete,
    FbPageSelector,
    ChannelSelector,AgentSelector,
    WaWebPhoneSelector,
    FbWaPhoneSelector,
    WaWebLabelSelector,
    OrganisationSelector,
    OrganisationQuickReplySelector,
    FbWaBaTemplateSelector,
    FbApplicationSelector,
    FbWaBASelector,
    FbIgUserSelector,
    SocialContentLabelOptionSelector,
    CustomFileUpdate,
    CustomFormInputGroup
  },
  emits: ['update:modelValue','changeFieldDisplay','changeFieldValue'],
  data() {
    return {
      value: undefined,
    };
  },
  watch: {
    modelValue(val) {
      this.value = val;
    },
    value(val) {
      if (this.modelValue != val) {
        this.$emit("update:modelValue",val);
        this.$emit("changeFieldValue",val);
      }
    },
  },
  mounted() {
    this.value = this.modelValue;
  },
  methods: {
    updateSubItemValue(val,index) {
      this.value[index] = val;
      this.updateFieldDisplay(this.value);
    },
    isArrayValue() {
      return this.field != null && this.value != null && Array.isArray(this.value);
    },
    radioChange(x) {
      // console.log(x);
    },
    getItemKey(item) {
      let key = item;
      if (this.field && this.field.getItemKey && typeof this.field.getItemKey == 'function') {
        key = this.field.getItemKey(item);
      }
      return key;
    },
    updateFieldDisplay(val) {
      if (val != null) {
        this.$emit('changeFieldDisplay',val);
      }
    },
    getAdditionalData(additionalData) {
      if (additionalData != null) {
        this.$emit("changeFieldValue",this.modelValue,additionalData);
      }
    },
    textAreaAdjust(e) {
      if (e.target) {
        const element = e.target;
        if (element?.scrollHeight) {
          element.style.height = (element?.scrollHeight) + "px";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.field-container {
  display: flex;
  flex: 1 1 auto;

  .sub-form-item-array-container {
    display: flex;
    flex-direction: row;
  }

  &.checkbox-container {
    flex: 0 1 auto;

    label {
      margin: 0.2rem 0.2rem 0 0.2rem;
    }

    &::v-deep(.con-vs-checkbox) {
      display: inline-flex;
      margin: 0.2rem 0.2rem 0 0.2rem;

      input {
        min-height: 1.2rem;
      }

      .vs-checkbox {
        height: 1.2rem;
        width: 1.2rem;
      }

      .material-icons {
        font-size: 1rem;
      }
    }
  }

  .fb-waba-template-selector{
    width: 100%;
    max-width: 100%;
  }

  &::v-deep(.autocomplete) input,
  &::v-deep(.fb-waba-template-selector) select,
  input,
  select,
  textarea {
    flex: 1 0;
    width: 100%;



    max-height: 4rem;
    min-height: 2rem;

    border: none;
    outline: none;
    background: rgb(var(--input-background-color));
    border-radius: 0.4rem;
    padding: 0.5rem;
    font-size: 0.9rem;
    align-items: center;
    color: rgb(var(--input-color));
    line-height: 1.2rem;
    font-family: 'Open Sans';

    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;

    position: relative;
    padding: 7px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .1);
  }

  textarea {
    max-height: 10rem;
    min-height: 2.4rem;
    height: 2.4rem;
    font-size: .8rem;
    line-height: 1rem;
    height: initial;
    resize: none;
  }

  input {
    max-width: 14rem;
    min-width: 8rem;
    height: 2.4rem;
    clear: both;
    color: inherit;
  }

  select {
    color: inherit;
    max-width: 14rem;
    min-width: 10rem;
    display: inline;
    height: 2.4rem;
  }

  .custom-radio-container {
    margin: 0.2rem 0.2rem 0 0.2rem;
    display: flex;
    flex: 1 0 auto;

    &::v-deep(.con-vs-radio) {
      font-size: 0.9rem;
      font-weight: lighter;
    }
  }
}
</style>
