<template>
  <CustomAvatar class="fb-user-avatar" :class="{
    'page-user': fbUser?.isPage,
    'clickable-avatar': navigateOnClick,
  }" :readyToDisplay="readyToDisplay" :dataIdentifier="fbUserId"
    :imageUrl="fbUser?.imageUrl ?? fbUser?.data?.picture?.data?.url" :showImage="showImage || showImageOnly" :displaySmall="displaySmall"
    :showImageOnlyDesc="showImageOnlyDesc" @itemClick="navigateToAccount" @refreshClick="getUser(true)"
    :showDetails="showDetails && fbUser != null && showImageOnly != true">
    <template v-slot:defaultImage>
      <i class="fa-solid fa-person"></i>
    </template>
    <template v-slot:mainData v-if="fbUser && showImageOnly != true">
      <div class="item-parent-details">
        <FbPageAvatar v-if="showPage" :organisationId="organisationId" :pageId="fbPageId" :displaySmall="true"
          :showImage="false">
        </FbPageAvatar>
      </div>
      <div>
        {{ fbUser.name }}
      </div>
    </template>
    <template v-slot:dataDetails  v-if="fbUser && showImageOnly != true" >
      <slot name="dataDetails">
        <div class="user-details-container">
        </div>
      </slot>
    </template>
    <template v-slot:additionalContainers v-if="fbUser && showImageOnly != true">
      <div class="fb-user-icon-container">
        <div class="fb-user-type">
          <i v-if="fbUser?.isPage == true" class="fa-solid fa-crown"></i>
          <i v-else class="fa-regular fa-user"></i>
        </div>
      </div>
    </template>
    <template v-slot:popupData>
      <div v-if="fbUser" v-html="getObjectDefaultDisplay()"></div>
    </template>
  </CustomAvatar>
</template>

<script>
import { displayObjectData } from "@/helpers/common";
import FbPageAvatar from "./FbPageAvatar.vue";

export default {
  name: "FbUserAvatar",
  props: {
    fbUserId: { type: String },
    organisationId: { type: String },
    pageId: { type: String },
    navigateOnClick: { type: Boolean,default: () => false },
    showImage: { type: Boolean,default: () => true },
    showDetails: { type: Boolean,default: () => true },
    displaySmall: { type: Boolean,default: () => false },
    showPage: { type: Boolean,default: () => false },
    showImageOnly: { type: Boolean,default: () => false },
  },
  data() {
    return {
      readyToDisplay: false,
      fbUser: undefined,
      showImageOnlyDesc: undefined
    };
  },
  watch: {
    fbUserId() {
      this.getUser();
    },
    showImageOnly() {
      if (this.showImageOnly) {
        this.showImageOnlyDesc = this.fbUser?.name ?? "-";
      }
    },
    "$store.state.lastUpdateNotification"(val) {
      if (
        val &&
        this.fbUser &&
        val.objectType == "FBUSER_UPDATED" &&
        val.data &&
        Array.isArray(val.data) &&
        val.data.find((x) => x._id == this.fbUser._id)
      ) {
        this.getUser();
      }
    },
  },
  components: {
    FbPageAvatar
  },
  computed: {
    fbPageId() {
      return this.fbUser?.fbPageIds?.find(x => this.pageId == null || x == this.pageId)
    }
  },
  async mounted() {
    await this.getUser();
    this.readyToDisplay = true;
  },
  methods: {

    navigateToAccount() {
      if (this.navigateOnClick == false) {
        return;
      }
      const query = {};
      if (this.organisationId) {
        query.organisationId = this.organisationId;
      }
      if (this.pageId) {
        query.pageId = this.pageId;
      }

      this.$router.push({
        name: "fbUserDetails",
        params: { id: this.fbUserId },
        query,
      });
    },
    async getUser(force = false) {
      if (this.fbUserId) {
        await this.$store
          .dispatch("fbUser/getById",{
            id: this.fbUserId,
            organisationId: this.organisationId,
            pageId: this.pageId,
            force,mergeRequest: true
          })
          .then((d) => {
            this.fbUser = d;

          }).catch(() => undefined);
      }

      if (this.showImageOnly) {
        this.showImageOnlyDesc = this.fbUser?.name ?? "-";
      }
      return;
    },
    getObjectDefaultDisplay() {
      return displayObjectData(this.fbUser?.data ?? {});
    },
  },
};
</script>

<style lang="scss" scoped>
.fb-user-avatar {
  &.page-user {
    &::v-deep(.avatar-img-container) {
      opacity: 0.7;
    }

    &::v-deep(.main-data-container) {
      color: gray;
    }
  }

  &::v-deep(.avatar-details) {
    min-width: 6rem;
  }

  .fb-user-icon-container {
    display: flex;
    align-items: center;
    padding: .2rem .3rem;

    .fb-user-type {
      font-size: .7rem;
    }
  }
}

.user-details-container {
  word-break: break-word;
}
</style>