import { io } from "socket.io-client";
import { store } from "../store";
import config from "@/config";
import i18nService from "../controllers/i18nService";

const socket = io(config.backendUrl,{
  autoConnect: false,
  path: "/io",
  withCredentials: true,
  transports: ["websocket"],
  extraHeaders: {
    "my-custom-header": "abcd",
  },
});

socket.on("connect",() => { });

const managedEvents = [
  "NEW_FB_MESSAGE",
  "READ_FB_MESSAGE",
  "DELIVERY_FB_MESSAGE",
  "REACTION_FB_MESSAGE",
  "NEW_FB_POST",
  "NEW_FB_COMMENT",
  "NEW_FB_COMMENT_REACTION",
  "NEW_FB_POST_REACTION",
  "UPDATE_FB_POST",
  "OBJECT_UPDATE",
  "NEW_IG_COMMENT",
  "NEW_IG_MESSAGE",
];

socket.onAny((a,b,c) => {
  if (managedEvents.find((x) => x == a) == null) {
    if (a == "FACEBOOK_REQUEST_ERROR") {
      if (
        b?.stack &&
        typeof b?.stack == "string" &&
        /REMOTE_API_REQUEST_ERROR/.test(b.stack)
      ) {
        store.dispatch("showNotification",{
          type: "warning",
          title: i18nService.i18n.global.t("messages.FACEBOOK_REQUEST_ERROR"),
          text: "",
        });
        return;
      }
    }
    store.dispatch("showNotification",{
      title: a,
      text: JSON.stringify({ b,c }),
    });
  }
});

const objectUpdateQueueSet = {};

function manageCacheUpdates(key,item) {
  if (key && typeof key == "string") {
    if (key.match(/^FBAPPLICATION/)) {
      store.dispatch("fbApplication/clearCachedData",{ item })
    }
    if (key.match(/^FBACCESSTOKEN/)) {
      store.dispatch("fbAccessToken/clearCachedData",{ item })
    }
    if (key.match(/^FBPAGE/)) {
      store.dispatch("fbPage/clearCachedData",{ item })
    }
    if (key.match(/^FBUSER/)) {
      store.dispatch("fbUser/clearCachedData",{ item })
    }
    if (key.match(/^FBLABEL/)) {
      store.dispatch("fbLabel/clearCachedData",{ item })
    }
    if (key.match(/^CUSTOMER/)) {
      store.dispatch("customer/clearCachedData",{ item })
    }

    
    if (key.match(/^FBREACTION/)) {
      store.dispatch("fbReaction/clearCachedData",{ item })
    }
    
    if (key.match(/^FBPOST/)) {
      store.dispatch("fbPost/clearCachedData",{ item })
    }
    if (key.match(/^FBCOMMENT/)) {
      store.dispatch("fbComment/clearCachedData",{ item })
    }
    if (key.match(/^FBCONVERSATION/)) {
      store.dispatch("fbConversation/clearCachedData",{ item })
    }
    if (key.match(/^FBMESSAGE/)) {
      store.dispatch("fbMessage/clearCachedData",{ item })
    }

    if (key.match(/^FBIGUSER/)) {
      store.dispatch("igUser/clearCachedData",{ item })
    }
    if (key.match(/^FBIGCUSTOMERUSER/)) {
      store.dispatch("igCustomerUser/clearCachedData",{ item })
    }
    if (key.match(/^FBIGMEDIA/)) {
      store.dispatch("igMedia/clearCachedData",{ item })
    }
    if (key.match(/^FBIGCOMMENT/)) {
      store.dispatch("igComment/clearCachedData",{ item })
    }
    if (key.match(/^FBIGCONVERSATION/)) {
      store.dispatch("igConversation/clearCachedData",{ item })
    }
    if (key.match(/^FBIGMESSAGE/)) {
      store.dispatch("igMessage/clearCachedData",{ item })
    }

    if (key.match(/^FBWABA/)) {
      store.dispatch("fbWaBA/clearCachedData",{ item })
    }
    if (key.match(/^FBWAPHONE/)) {
      store.dispatch("fbWaPhone/clearCachedData",{ item })
    }
    if (key.match(/^FBWACONTACT/)) {
      store.dispatch("fbWaContact/clearCachedData",{ item })
    }
    if (key.match(/^FBWACONVERSATION/)) {
      store.dispatch("fbWaConversation/clearCachedData",{ item })
    }
    if (key.match(/^FBWAMESSAGE/)) {
      store.dispatch("fbWaMessage/clearCachedData",{ item })
    }
    if (key.match(/^FBWAMESSAGETEMPLATE/)) {
      store.dispatch("fbWaMessageTemplate/clearCachedData",{ item })
    }


    if (key.match(/^WAWEBSESSION/)) {
      store.dispatch("waWebSession/clearCachedData",{ item })
    }
    if (key.match(/^WAWEBPHONE/)) {
      store.dispatch("waWebPhone/clearCachedData",{ item })
    }
    
    if (key.match(/^WAWEBAPP/)) {
      store.dispatch("waWebApplication/clearCachedData",{ item })
    }

    if (key.match(/^WAWEBMESSAGE/)) {
      store.dispatch("waWebMessage/clearCachedData",{ item })
    }
    if (key.match(/^WAWEBCHAT/)) {
      store.dispatch("waWebChat/clearCachedData",{ item })
    }
    if (key.match(/^WAWEBCONTACT/)) {
      store.dispatch("waWebContact/clearCachedData",{ item })
    }
    if (key.match(/^WAWEBLABEL/)) {
      store.dispatch("waWebLabel/clearCachedData",{ item })
    }

    if (key.match(/^ORGANISATION/)) {
      store.dispatch("organisation/clearCachedData",{ item })
    }

    if (key.match(/^ORGANISATIONSUBSCRIPTION/)) {
      store.dispatch("organisation/clearCachedData",{ item })
    }


    if (key.match(/^CHANNEL/)) {
      store.dispatch("channel/clearCachedData",{ item })
    }

    if (key.match(/^SOCIALCONTENTLABEL/)) {
      store.dispatch("socialContentLabel/clearCachedData",{ item })
    }

        if (key.match(/^SOCIALCONTENTLABELOPTION/)) {
      store.dispatch("socialContentLabelOption/clearCachedData",{ item })
    }

    if (key.match(/^ORGANISATIONQUICKREPLY/)) {
      store.dispatch("organisationQuickReply/clearCachedData",{ item })
    }
    if (key.match(/^ORGANISATIONMEMBERSHIP/)) {
      store.dispatch("organisationMembership/clearCachedData",{ item });
      store.dispatch("organisation/clearCachedData",{ item });
    }

    if (key.match(/^USER/)) {
      store.dispatch("user/clearCachedData",{ item })
    }
    if (key.match(/^NOTIFICATION/)) {
      store.dispatch("notification/clearCachedData",{ item })
    }
  }
}

function manageUpdateQueue() {
  Object.entries(objectUpdateQueueSet).forEach(async ([key,queue]) => {
    if (key && typeof key == "string") {
      if (Array.isArray(queue) && queue.length > 0) {
        queue.map(vals => {
          vals.map(val => {
            manageCacheUpdates(key,val);
          })
        });

        await new Promise((res) => {
          const t = setTimeout(() => {
            store.dispatch("objectUpdated",{
              objectType: key,
              data: queue.reduce((p,c) => {
                if (Array.isArray(c)) {
                  c.forEach(x => {
                    p.push(x);
                  });
                } else {
                  p.push(c);
                }
                return p;
              },[]),
            });
            res(true);
            clearTimeout(t);
          },100);
        })
      }
      delete objectUpdateQueueSet[key];
    }
  });
}

function getQueueKey(key) {
  return key && typeof key == "string" ? key : "notString";
}

setInterval(() => {
  manageUpdateQueue();
},1000);

socket
  .on("OBJECT_UPDATE",(objectType,data) => {
    objectUpdateQueueSet[getQueueKey(objectType)] =
      objectUpdateQueueSet[getQueueKey(objectType)] ?? [];
    objectUpdateQueueSet[getQueueKey(objectType)].push(data);
  })
  .on("NEW_FB_MESSAGE",() => {
    store.dispatch("showNotification",{
      title: i18nService.i18n.global.t("messages.newMessageReceived"),
    });
  })
  .on("READ_FB_MESSAGE",() => {
    store.dispatch("showNotification",{
      title: i18nService.i18n.global.t("messages.messageRead"),
    });
  })
  .on("DELIVERY_FB_MESSAGE",() => {
    store.dispatch("showNotification",{
      title: i18nService.i18n.global.t("messages.messageDeliveried"),
    });
  })
  .on("REACTION_FB_MESSAGE",() => {
    store.dispatch("showNotification",{
      title: i18nService.i18n.global.t("messages.messageReacted"),
    });
  })
  .on("NEW_FB_POST",() => {
    store.dispatch("showNotification",{
      title: i18nService.i18n.global.t("messages.newPost"),
    });
  })
  .on("NEW_FB_COMMENT",() => {
    store.dispatch("showNotification",{
      title: i18nService.i18n.global.t("messages.newComment"),
    });
  })
  .on("NEW_FB_COMMENT_REACTION",() => {
    store.dispatch("showNotification",{
      title: i18nService.i18n.global.t("messages.reactedToComment"),
    });
  })
  .on("NEW_FB_POST_REACTION",() => {
    store.dispatch("showNotification",{
      title: i18nService.i18n.global.t("messages.reactedToPost"),
    });
  })
  .on("NEW_IG_COMMENT",() => {
    store.dispatch("showNotification",{
      title: i18nService.i18n.global.t("messages.NEW_IG_COMMENT"),
    });
  })
  .on("NEW_IG_MESSAGE",() => {
    store.dispatch("showNotification",{
      title: i18nService.i18n.global.t("messages.NEW_IG_MESSAGE"),
    });
  })
  .on("READ_IG_MESSAGE",() => {
    store.dispatch("showNotification",{
      title: i18nService.i18n.global.t("messages.messageRead"),
    });
  })
  .on("UPDATE_FB_POST",() => {
    store.dispatch("showNotification",{
      title: i18nService.i18n.global.t("messages.postUpdated"),
    });
  });
socket.on("disconnect",() => { });

export default socket;
