<template>
  <div>
    <CustomAvatar class="application-avatar" :readyToDisplay="application != null" :dataIdentifier="applicationId"
      :displaySmall="displaySmall" :imageUrl="application?.imageUrl ?? application?.data?.photo_url"
      :showDetails="showDetails" :showInCard="showInCard" :showImage="showImage" :actionMenuOptions="menuOptions"
      @actionMenuClick="clickMenuAction" @itemClick="navigateToApplication" @refreshClick="getApplication(true)">
      <template v-slot:defaultImage>
        <i class="fa-solid fa-server"></i>
      </template>
      <template v-slot:mainData>
        {{ application.name }}
      </template>
      <template v-slot:dataDetails>
        {{ application.loginRedirectUri }}
      </template>
      <template v-slot:additionalButtons>
        <div class="facebook-login-button-container"
          v-if="allowedServices && showLink && organisationId && applicationId">
          <CustomButton v-if="allowedServices?.FaFacebook" class="facebook-button" buttonClass="primary-button"
            iconClass="fa-brands fa-facebook" @click.stop="() => connectToFbAccount()"></CustomButton>
          <CustomButton v-if="allowedServices?.FaInstagram" class="instagram-button" buttonClass="primary-button"
            iconClass="fa-brands fa-instagram" @click.stop="() => connectToFbAccount('instagram')"></CustomButton>
          <CustomButton v-if="allowedServices?.FaWhatsapp" class="whatsapp-button" buttonClass="primary-button"
            iconClass="fa-brands fa-whatsapp" @click.stop="() => connectToFbAccount('whatsapp')"></CustomButton>
          <CustomPopup ref="tokenPopup" :title="$i18n.t('page.fbToken')" :showButton="false">
            <TokenEdit :initialData="initialToken" :organisationId="organisationId" :applicationId="applicationId"
              :manuelAdd="true" tokenType="WHATSAPP_BEARER" @submitData="whatsappTokenAdded"></TokenEdit>
          </CustomPopup>
        </div>
      </template>
      <template v-slot:popupData>
        <div v-html="getObjectDefaultDisplay()"></div>
      </template>
    </CustomAvatar>
  </div>
</template>

<script>
import { displayObjectData } from "@/helpers/common";
export default {
  name: "FbApplicationAvatar",
  props: {
    organisationId: { type: String },
    applicationId: { type: String },
    navigateOnClick: { type: Boolean,default: () => false },
    showImage: { type: Boolean,default: () => true },
    showDetails: { type: Boolean,default: () => false },
    showInCard: { type: Boolean,default: () => false },
    displaySmall: { type: Boolean,default: () => false },
    showLink: { type: Boolean,default: () => false },
  },
  data() {
    return {
      application: undefined,
      allowedServices: undefined,
      menuOptions: [],
      initialToken: {},
    };
  },
  watch: {
    applicationId() {
      this.getApplication();
    },
  },
  computed: {
    tokenPopup: function () {
      return this.$refs.tokenPopup &&
        Array.isArray(this.$refs.tokenPopup) &&
        this.$refs.tokenPopup.length > 0
        ? this.$refs.tokenPopup[0]
        : this.$refs.tokenPopup;
    },
  },
  mounted() {
    this.getApplication();
  },
  methods: {
    showWhatsappTokenAdd() {
      if (this.tokenPopup) {
        this.tokenPopup.showPopup();
      }
    },
    whatsappTokenAdded() {
      if (this.tokenPopup) {
        this.tokenPopup.closePopup(true);
      }
    },
    clickMenuAction() { },
    connectToFbAccount(platform = "all") {
      this.$store
        .dispatch("auth/connectToFbAccount",{
          appReturn: this.$route.fullPath,
          organisationId: this.organisationId,
          appId: this.applicationId,
          platform,
        })
        .then((d) => {
          if (d.data.url) {
            return window.open(d.data.url,"_blank");
          }
        });
    },
    navigateToApplication() {
      if (this.navigateOnClick == false) {
        return;
      }
      const query = {};
      if (this.organisationId) {
        query.organisationId = this.organisationId;
      }
      this.$router.push({
        name: "applicationDetails",
        params: { id: this.applicationId },
        query,
      });
    },
    getApplication(force = false) {
      return this.$store
        .dispatch("fbApplication/getById",{
          organisationId: this.organisationId,
          id: this.applicationId,
          force,
          mergeRequest: true
        })
        .then((d) => {
          this.application = d;
          if (this.organisationId) {
            this.$store
              .dispatch("organisation/getById",{ id: this.organisationId })
              .then((d) => {
                this.allowedServices = d.allowedServices;

              });
          } else {
            this.allowedServices = [];
          }
        });
    },
    getObjectDefaultDisplay() {
      return displayObjectData(this.application?.data ?? {});
    },
  },
};
</script>

<style lang="scss" scoped>
.application-avatar {
  display: inline-flex;
  align-items: center;

  .facebook-login-button-container {
    display: flex;
    flex-direction: row;

    .vs-button {
      margin: 0.3rem;
      padding: 0.5rem;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);

      width: 2.4rem;
      height: 2.4rem;

      &.facebook-button {
        background: #5b7bd5;
        background: radial-gradient(circle at 100% 30%,
            #698ae4 0%,
            #4c67b2 90%) !important;
        background-color: #25d366;
      }

      &.whatsapp-button {
        background: #25d366;
        background: radial-gradient(circle at 50% 120%,
            #26de6a 0%,
            #17ab4d 90%) !important;
        background-color: #25d366;
      }

      &.instagram-button {
        background: #d6249f;
        background: radial-gradient(circle at 30% 100%,
            #fdf497 0%,
            #fdf497 5%,
            #fd5949 45%,
            #d6249f 60%,
            #285aeb 90%) !important;
      }

      .vs-button-text {
        display: inline-flex;
        align-items: center;
        justify-content: space-around;

        &>* {
          margin: 0.1rem 0.3rem;

          &:last-child {
            margin-right: 0;
          }

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
}
</style>