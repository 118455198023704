<template>
  <div class="custom-tab-container" :hidden="!isActive">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "CustomTab",
  props: {
    label: { type: String },
    icon: { type: String },
    color: { type: String },
    isActive: { type: Boolean, default: ()=>false },
  },
  emits: ["changed"],
  data() {
    return {
    }
  },
  computed: {

  },
  methods: {
  },
};
</script>

<style lang="scss" scoped></style>