import { createWebHistory, createRouter } from "vue-router";
import { store } from "../store";

const genetareOrganisationsBreadcrumbItems = ({ store, i18n }) => {
  if (store.state?.auth?.stats?.multipleOrganisation) {
    return [
      {
        title: i18n.t("page.organisations"),
        routeData: {
          name: "organisations",
        },
      },
    ];
  } else {
    return undefined;
  }
};

const generateOrganisationDetailBreadcrumbItems = async (
  { store, i18n },
  organisationId
) => {
  const breadcrumbItems =
    genetareOrganisationsBreadcrumbItems({ i18n, store }) ?? [];

  let title = undefined;
  if (store.state?.auth?.stats?.multipleOrganisation == true) {
    title = await store
      .dispatch("organisation/getById", {
        id: organisationId,
        mergeRequest: true,
      })
      .then((data) => data?.name)
      .catch(() => undefined);
  }

  breadcrumbItems.push({
    title: title ?? i18n.t("page.organisation"),
    routeData: {
      name: "organisationDetails",
      params: { id: organisationId },
    },
  });

  return breadcrumbItems;
};
const Login = {
  path: "/login",
  name: "loginRoute",
  component: () => import("./routes/NonAuthRoute.vue"),
  children: [
    {
      path: "",
      name: "login",
      component: () => import("./routes/Login.vue"),
    },
    {
      path: "/login/ors",
      name: "loginWithOrs",
      component: () => import("./routes/OrsLogin.vue"),
    },
  ],
  meta: {
    requireNonAuth: true,
  },
};

const SimpleActionView = {
  path: "/app",
  name: "simpleActionRoute",
  component: () => import("./routes/SimplifiedActionRoute.vue"),
  meta: {
    requireAuth: true,
  },
  children: [
    {
      path: "",
      name: "appView",
      component: () => import("./routes/AppView.vue"),
      meta: {
        requireAuth: true,
      },
    },
  ],
};

const Auth = {
  path: "/",
  name: "auth",
  component: () => import("./routes/AuthRoute.vue"),
  meta: {
    requireAuth: true,
  },
  children: [
    {
      path: "",
      name: "dashboard",
      component: () => import("./routes/Dashboard.vue"),
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "profile",
      name: "profile",
      component: () => import("./routes/Profile.vue"),
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "addFacebookAccount",
      name: "addFacebook",
      component: () => import("./routes/AddFacebookAccount.vue"),
      meta: {
        requireAuth: true,
      },
    },
    {
      path: "users",
      name: "userRoute",
      component: () => import("./routes/OnlyRouter.vue"),
      meta: {
        requireAuth: true,
      },
      children: [
        {
          path: "",
          name: "users",
          component: () => import("./routes/Users.vue"),
        },
        {
          path: ":id",
          name: "userDetails",
          component: () => import("./routes/UsersDetails.vue"),
        },
      ],
    },
    {
      path: "channels",
      name: "channelRoute",
      component: () => import("./routes/OnlyRouter.vue"),
      meta: {
        requireAuth: true,
      },
      children: [
        {
          path: "",
          name: "channels",
          component: () => import("./routes/Channels.vue"),
        },
        {
          path: ":id",
          name: "channelDetails",
          component: () => import("./routes/ChannelDetails.vue"),
        },
      ],
    },
    {
      path: "notifications",
      name: "notificationRoute",
      component: () => import("./routes/OnlyRouter.vue"),
      meta: {
        requireAuth: true,
      },
      children: [
        {
          path: "",
          name: "notifications",
          component: () => import("./routes/Notifications.vue"),
        },
        {
          path: ":id",
          name: "notificationDetails",
          component: () => import("./routes/NotificationDetails.vue"),
        },
      ],
    },
    {
      path: "customers",
      name: "customerRoute",
      component: () => import("./routes/OnlyRouter.vue"),
      meta: {
        requireAuth: true,
      },
      children: [
        {
          path: "",
          name: "customers",
          component: () => import("./routes/Customers.vue"),
        },
        {
          path: ":id",
          name: "customerDetails",
          component: () => import("./routes/CustomerDetails.vue"),
        },
      ],
    },
    {
      path: "channels",
      name: "channelRoute",
      component: () => import("./routes/OnlyRouter.vue"),
      meta: {
        requireAuth: true,
      },
      children: [
        {
          path: "",
          name: "channels",
          component: () => import("./routes/Channels.vue"),
        },
        {
          path: ":id",
          name: "channelDetails",
          component: () => import("./routes/ChannelDetails.vue"),
        },
      ],
    },
    {
      path: "organisations",
      name: "organisationRoute",
      component: () => import("./routes/OnlyRouter.vue"),
      children: [
        {
          path: "",
          name: "organisations",
          component: () => import("./routes/Organisations.vue"),
          meta: {
            breadcrumbItems: ({ i18n, store }) => {
              return genetareOrganisationsBreadcrumbItems({ i18n, store });
            },
          },
        },
        {
          path: ":id",
          name: "organisationDetails",
          component: () => import("./routes/OrganisationDetails.vue"),
          meta: {
            breadcrumbItems: async ({ i18n, store, route }) => {
              const organisationId =
                route.query?.organisationId ?? route.params.id;
              return generateOrganisationDetailBreadcrumbItems(
                { i18n, store },
                organisationId
              );
            },
          },
        },
      ],
    },
    {
      path: "organisationSubscriptions",
      name: "organisationSubscriptionRoute",
      component: () => import("./routes/OnlyRouter.vue"),
      children: [
        {
          path: "",
          name: "organisationSubscriptions",
          component: () => import("./routes/OrganisationSubscriptions.vue"),
        },
        {
          path: ":id",
          name: "organisationSubscriptionDetails",
          component: () =>
            import("./routes/OrganisationSubscriptionDetails.vue"),
        },
      ],
    },
    {
      path: "organisationMemberships",
      name: "organisationMembershipsRoute",
      component: () => import("./routes/OnlyRouter.vue"),
      children: [
        {
          path: "",
          name: "organisationMemberships",
          component: () => import("./routes/OrganisationMemberships.vue"),
        },
        {
          path: ":id",
          name: "organisationMembershipDetails",
          component: () => import("./routes/OrganisationMembershipDetails.vue"),
        },
      ],
    },
    {
      path: "management",
      name: "managementRoute",
      component: () => import("./routes/OnlyRouter.vue"),
      children: [
        {
          meta: {
            requireManager: true,
          },
          path: "/reports",
          name: "reports",
          component: () => import("./routes/Reports.vue"),
        },
        {
          meta: {
            requireAdmin: true,
          },
          path: "/admin",
          name: "admin",
          component: () => import("./routes/Admin.vue"),
        },
      ],
    },
    {
      path: "facebook",
      name: "fbMainRoute",
      component: () => import("./routes/OnlyRouter.vue"),
      children: [
        {
          path: "pages",
          name: "pageRoute",
          component: () => import("./routes/OnlyRouter.vue"),
          children: [
            {
              path: "",
              name: "pages",
              component: () => import("./routes/facebook/FbPages.vue"),
              meta: {
                breadcrumbItems: async ({ i18n }) => {
                  const breadcrumbItems = [];
                  breadcrumbItems.push({
                    title: i18n.t("page.pages"),
                    routeData: {
                      name: "pages",
                    },
                  });
                  return breadcrumbItems;
                },
              },
            },
            {
              path: ":id",
              name: "pageDetails",
              component: () => import("./routes/facebook/FbPageDetails.vue"),
              meta: {
                breadcrumbItems: async ({ i18n, store, route }) => {
                  const breadcrumbItems = [];
                  const organisationId = route.query?.organisationId;
                  if (organisationId) {
                    const items =
                      await generateOrganisationDetailBreadcrumbItems(
                        { i18n, store },
                        organisationId
                      );
                    if (items != null && Array.isArray(items)) {
                      breadcrumbItems.push(...items);
                    }
                    breadcrumbItems.push({
                      title: i18n.t("page.pages"),
                      routeData: {
                        name: "organisationDetails",
                        params: { id: organisationId },
                        query: { ["organisation-tab"]: "fb" },
                      },
                    });
                  } else {
                    breadcrumbItems.push({
                      title: i18n.t("page.pages"),
                      routeData: {
                        name: "pages",
                      },
                    });
                  }

                  const fbPageId = route.query?.fbPageId ?? route.params?.id;
                  const title = await store
                    .dispatch("fbPage/getById", {
                      id: fbPageId,
                      mergeRequest: true,
                    })
                    .then((data) => data?.name)
                    .catch(() => undefined);

                  breadcrumbItems.push({
                    title,
                    routeData: {
                      name: "pageDetails",
                      params: { id: fbPageId },
                      query: { organisationId },
                    },
                    selected: true,
                  });

                  return breadcrumbItems;
                },
              },
            },
            {
              path: ":id/posts/:postId",
              name: "pagePostDetails",
              component: () => import("./routes/facebook/FbPostDetails.vue"),
            },
            {
              path: ":id/posts/:postId/comments/:commentId",
              name: "pageCommentDetails",
              component: () => import("./routes/facebook/FbCommentDetails.vue"),
            },
            {
              path: ":id/conversations/:conversationId",
              name: "conversationDetails",
              component: () =>
                import("./routes/facebook/FbConversationDetails.vue"),
            },
            {
              path: ":id/conversations/:conversationId/messages/:messageId",
              name: "messageDetails",
              component: () => import("./routes/facebook/FbMessageDetails.vue"),
            },
          ],
        },
        {
          path: "conversations",
          name: "conversationsRoute",
          component: () => import("./routes/OnlyRouter.vue"),
          children: [
            {
              path: "",
              name: "conversations",
              component: () => import("./routes/facebook/FbConversations.vue"),
            },
          ],
        },
        {
          path: "posts",
          name: "postRoute",
          component: () => import("./routes/OnlyRouter.vue"),
          children: [
            {
              path: "",
              name: "posts",
              component: () => import("./routes/facebook/FbPosts.vue"),
            },
          ],
        },
        {
          path: "comments",
          name: "commentRoute",
          component: () => import("./routes/OnlyRouter.vue"),
          children: [
            {
              path: "",
              name: "comments",
              component: () => import("./routes/facebook/FbComments.vue"),
            },
          ],
        },
        {
          path: "tokens",
          name: "tokenRoute",
          component: () => import("./routes/OnlyRouter.vue"),
          children: [
            {
              path: "",
              name: "tokens",
              component: () => import("./routes/facebook/FbTokens.vue"),
            },
            {
              path: ":id",
              name: "tokenDetails",
              component: () => import("./routes/facebook/FbTokenDetails.vue"),
            },
          ],
        },
        {
          path: "applications",
          name: "applicationRoute",
          component: () => import("./routes/OnlyRouter.vue"),
          children: [
            {
              path: "",
              name: "applications",
              component: () => import("./routes/facebook/FbApplications.vue"),
            },
            {
              path: ":id",
              name: "applicationDetails",
              component: () =>
                import("./routes/facebook/FbApplicationDetails.vue"),
            },
          ],
        },
        {
          path: "users",
          name: "fbUserRoute",
          component: () => import("./routes/OnlyRouter.vue"),
          children: [
            {
              path: "",
              name: "fbUsers",
              component: () => import("./routes/facebook/FbUsers.vue"),
            },
            {
              path: ":id",
              name: "fbUserDetails",
              component: () => import("./routes/facebook/FbUserDetails.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "instagram",
      name: "igMainRoute",
      component: () => import("./routes/OnlyRouter.vue"),
      children: [
        {
          path: "users",
          name: "igUserRoute",
          component: () => import("./routes/OnlyRouter.vue"),
          children: [
            {
              path: "",
              name: "igUsers",
              component: () => import("./routes/instagram/IgUsers.vue"),
              meta: {
                breadcrumbItems: async ({ i18n }) => {
                  const breadcrumbItems = [];
                  breadcrumbItems.push({
                    title: i18n.t("page.fbIgUsers"),
                    routeData: {
                      name: "igUsers",
                    },
                  });
                  return breadcrumbItems;
                },
              },
            },
            {
              path: ":id",
              name: "igUserDetails",
              component: () => import("./routes/instagram/IgUserDetails.vue"),
              meta: {
                breadcrumbItems: async ({ i18n, store, route }) => {
                  const breadcrumbItems = [];
                  const organisationId = route.query?.organisationId;
                  if (organisationId) {
                    const items =
                      await generateOrganisationDetailBreadcrumbItems(
                        { i18n, store },
                        organisationId
                      );
                    if (items != null && Array.isArray(items)) {
                      breadcrumbItems.push(...items);
                    }
                    breadcrumbItems.push({
                      title: i18n.t("page.fbIgUsers"),
                      routeData: {
                        name: "organisationDetails",
                        params: { id: organisationId },
                        query: { ["organisation-tab"]: "ig" },
                      },
                    });
                  } else {
                    breadcrumbItems.push({
                      title: i18n.t("page.fbIgUsers"),
                      routeData: {
                        name: "igUsers",
                      },
                    });
                  }

                  const fbIgUserId = route.query?.fbIgUserId ?? route.params?.id;
                  const title = await store
                    .dispatch("igUser/getById", {
                      id: fbIgUserId,
                      mergeRequest: true,
                    })
                    .then((data) => data?.name)
                    .catch(() => undefined);

                  breadcrumbItems.push({
                    title,
                    routeData: {
                      name: "igUserDetails",
                      params: { id: fbIgUserId },
                      query: { organisationId },
                    },
                    selected: true,
                  });

                  return breadcrumbItems;
                },
              },
            },
          ],
        },
        {
          path: "customers",
          name: "igCustomerUserRoute",
          component: () => import("./routes/OnlyRouter.vue"),
          children: [
            {
              path: "",
              name: "igCustomerUsers",
              component: () => import("./routes/instagram/IgCustomerUsers.vue"),
            },
            {
              path: ":id",
              name: "igCustomerUserDetails",
              component: () =>
                import("./routes/instagram/IgCustomerUserDetails.vue"),
            },
          ],
        },

        {
          path: "media",
          name: "igMediaRoute",
          component: () => import("./routes/OnlyRouter.vue"),
          children: [
            {
              path: "",
              name: "igMedia",
              component: () => import("./routes/instagram/IgMedia.vue"),
            },
            {
              path: ":id",
              name: "igMediaDetails",
              component: () => import("./routes/instagram/IgMediaDetails.vue"),
            },
          ],
        },
        {
          path: "conversations",
          name: "igConversationRoute",
          component: () => import("./routes/OnlyRouter.vue"),
          children: [
            {
              path: "",
              name: "igConversations",
              component: () => import("./routes/instagram/IgConversations.vue"),
            },
            {
              path: ":id",
              name: "igConversationDetails",
              component: () =>
                import("./routes/instagram/IgConversationDetails.vue"),
            },
            {
              path: ":id/messages/:messageId",
              name: "igMessageDetails",
              component: () =>
                import("./routes/instagram/IgMessageDetails.vue"),
            },
          ],
        },
        {
          path: "comments",
          name: "igCommentRoute",
          component: () => import("./routes/OnlyRouter.vue"),
          children: [
            {
              path: "",
              name: "igComments",
              component: () => import("./routes/instagram/IgComments.vue"),
            },
            {
              path: ":id",
              name: "igCommentDetails",
              component: () =>
                import("./routes/instagram/IgCommentDetails.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "whatsapp",
      name: "waBAMainRoute",
      component: () => import("./routes/OnlyRouter.vue"),
      children: [
        {
          path: "ba",
          name: "waBARoute",
          component: () => import("./routes/OnlyRouter.vue"),
          children: [
            {
              path: "",
              name: "waBAs",
              component: () => import("./routes/whatsapp/WaBAs.vue"),
            },
            {
              path: ":id",
              name: "waBADetails",
              component: () => import("./routes/whatsapp/WaBADetails.vue"),
            },
          ],
        },
        {
          path: "phones",
          name: "waPhonesMainRoute",
          component: () => import("./routes/OnlyRouter.vue"),
          children: [
            {
              path: "",
              name: "waPhones",
              component: () => import("./routes/whatsapp/WaPhones.vue"),
            },
            {
              path: ":id",
              name: "waPhoneDetails",
              component: () => import("./routes/whatsapp/WaPhoneDetails.vue"),
            },
          ],
        },
        {
          path: "contacts",
          name: "waContactMainRoute",
          component: () => import("./routes/OnlyRouter.vue"),
          children: [
            {
              path: "",
              name: "waContacts",
              component: () => import("./routes/whatsapp/WaContacts.vue"),
            },
            {
              path: ":id",
              name: "waContactDetails",
              component: () => import("./routes/whatsapp/WaContactDetails.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "whatsapp-web",
      name: "waWebMainRoute",
      component: () => import("./routes/OnlyRouter.vue"),
      children: [
        {
          path: "applications",
          name: "waWebRoute",
          component: () => import("./routes/OnlyRouter.vue"),
          children: [
            {
              path: "",
              name: "waWebApplications",
              component: () => import("./routes/waWeb/WaWebApplications.vue"),
            },
            {
              path: ":id",
              name: "waWebApplicationDetails",
              component: () =>
                import("./routes/waWeb/WaWebApplicationDetails.vue"),
            },
          ],
        },
        {
          path: "sessions",
          name: "waWebSessionMainRoute",
          component: () => import("./routes/OnlyRouter.vue"),
          children: [
            {
              path: "",
              name: "waWebSessions",
              component: () => import("./routes/waWeb/WaWebSessions.vue"),
            },
            {
              path: ":id",
              name: "waWebSessionDetails",
              component: () => import("./routes/waWeb/WaWebSessionDetails.vue"),
            },
          ],
        },
        {
          path: "phones",
          name: "waWebPhonesMainRoute",
          component: () => import("./routes/OnlyRouter.vue"),
          children: [
            {
              path: "",
              name: "waWebPhones",
              component: () => import("./routes/waWeb/WaWebPhones.vue"),
            },
            {
              path: ":id",
              name: "waWebPhoneDetails",
              component: () => import("./routes/waWeb/WaWebPhoneDetails.vue"),
            },
          ],
        },
        {
          path: "chats",
          name: "waWebChatsMainRoute",
          component: () => import("./routes/OnlyRouter.vue"),
          children: [
            {
              path: "",
              name: "waWebChats",
              component: () => import("./routes/waWeb/WaWebChats.vue"),
            },
            {
              path: ":id",
              name: "waWebChatDetails",
              component: () => import("./routes/waWeb/WaWebChatDetails.vue"),
            },
          ],
        },
        {
          path: "contacts",
          name: "waWebContactsMainRoute",
          component: () => import("./routes/OnlyRouter.vue"),
          children: [
            {
              path: "",
              name: "waWebContacts",
              component: () => import("./routes/waWeb/WaWebContacts.vue"),
            },
            {
              path: ":id",
              name: "waWebContactDetails",
              component: () => import("./routes/waWeb/WaWebContactDetails.vue"),
            },
          ],
        },
      ],
    },
  ],
};

const routes = [
  Auth,
  Login,
  SimpleActionView,
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

//eslint-disable-next-line no-console
export async function checkRoute(to) {
  if (store.getters.appIsReady == false) {
    await new Promise((res) => {
      let iteration = 0;
      const t = setInterval(() => {
        if (store.getters.appIsReady || iteration > 20) {
          res();
          clearInterval(t);
        }
        iteration += 1;
      }, 200);
    });
  }

  if (to.matched && to.matched.length > 0) {
    const res = [...to.matched].reduce(async (p, c) => {
      if (p == null) {
        return await checkRoute(c);
      } else {
        return p;
      }
    }, null);
    if (res != null) {
      return res;
    }
  }

  if (
    to.meta?.requireNonAuth == true &&
    !((store.getters.authToken?.length ?? 0) == 0)
  ) {
    return to.query?.to ?? { name: "dashboard" };
  } else if (
    to.meta?.requireAuth == true &&
    !((store.getters.authToken?.length ?? 0) > 0)
  ) {
    return to.query?.to ?? { name: "login" };
  }
  //  else if (to.meta?.requireManager == true &&
  //   !((store.getters.authToken?.length ?? 0) > 0)
  //   ) {
  //   return to.query?.to ?? { name: "dashboard" };
  // }

  return;
}

router.beforeEach(async (to, from, next) => {
  const redirection = await checkRoute(to);
  store.dispatch("logger/log", {
    data: { name: "Router", from: from.fullPath, to: to.fullPath },
  });
  if (redirection == null) {
    next();
  } else {
    next(redirection);
  }
});

export default router;
