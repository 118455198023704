<template>
  <CustomPopup
    ref="commitPopup"
    :showButton="false"
    :title="$t('general.submitCommit')"
  >
  <CustomForm
      :fields="fields"
      :initialValue="initVal"
      @formSubmit="(val, e) => submitComment(val, e)"
    >
      <template v-slot:submitButton>
        <CustomButton
          buttonClass="success-button"
          buttonType="submit"
          buttonText="general.submit"
          ></CustomButton>
      </template>
    </CustomForm>
   
  </CustomPopup>
</template>

<script>
import { manageFormSubmitButtons } from '../../helpers/common';

export default {
    name: "FbCommentComponent",
    props: {
        postId: { type: String },
        commentId: { type: String },
        fbUserId: { type: String },
    },
    data() {
        return {
            fields: [],
            initVal: {
                message: "",
            },
        };
    },
    watch: {},
    mounted() {
        this.updateForm();
    },
    emits: ["submited"],
    computed: {
        commitPopup() {
            if (this.$refs.commitPopup) {
                return Array.isArray(this.$refs.commitPopup)
                    ? this.$refs.commitPopup[0]
                    : this.$refs.commitPopup;
            }
            this.$emit("submited");
            return undefined;
        },
    },
    methods: {
        updateForm() {
            const tempFields = [];
            const defaultVals = {};
            if (this.showTypeSelector) {
                defaultVals.type = "RESPONSE";
                tempFields.push({
                    type: "select",
                    key: "type",
                    label: this.$i18n?.t("model.basic-type"),
                    options: [
                        { label: this.$i18n?.t("model.message-type-RESPONSE"), val: "RESPONSE" },
                        { label: this.$i18n?.t("model.message-type-UPDATE"), val: "UPDATE" },
                        {
                            label: this.$i18n?.t("model.message-type-MESSAGE_TAG"),
                            val: "MESSAGE_TAG",
                        },
                    ],
                });
            }
            if (this.showTagSelector) {
                defaultVals.tag = "";
                tempFields.push({
                    type: "select",
                    key: "tag",
                    label: this.$i18n?.t("model.basic-tag"),
                    options: [
                        { label: this.$i18n?.t("general.unselected"), val: "" },
                        {
                            label: this.$i18n?.t("model.message-tag-ACCOUNT_UPDATE"),
                            val: "ACCOUNT_UPDATE",
                        },
                        {
                            label: this.$i18n?.t("model.message-tag-CONFIRMED_EVENT_UPDATE"),
                            val: "CONFIRMED_EVENT_UPDATE",
                        },
                        {
                            label: this.$i18n?.t("model.message-tag-CUSTOMER_FEEDBACK"),
                            val: "CUSTOMER_FEEDBACK",
                        },
                        {
                            label: this.$i18n?.t("model.message-tag-HUMAN_AGENT"),
                            val: "HUMAN_AGENT",
                        },
                        {
                            label: this.$i18n?.t("model.message-tag-POST_PURCHASE_UPDATE"),
                            val: "POST_PURCHASE_UPDATE",
                        },
                    ],
                });
            }
            tempFields.push({
                type: "textarea",
                key: "message",
                label: this.$i18n?.t("general.message"),
                placeholder: this.$i18n?.t("general.message"),
            });
            defaultVals.message = "";
            this.fields = tempFields;
            this.initVal = defaultVals;
        },
        async submitComment(val, e) {
            manageFormSubmitButtons(e, ()=>{
            val = Object.fromEntries(Object.entries(val).filter(([key, val]) => !(val == null || (typeof val == "string" && !(val.length > 0)))));
            if (this.commentId) {
                return this.$store
                    .dispatch("fbComment/submitComment", {
                    id: this.commentId,
                    data: {
                        ...val,
                    },
                })
                    .then((d) => {
                    this.submitSuccess(d);
                });
            }
            else if (this.postId) {
                return this.$store
                    .dispatch("fbPost/submitComment", {
                    id: this.postId,
                    data: {
                        ...val,
                    },
                })
                    .then((d) => {
                    this.submitSuccess(d);
                });
            }
        });
        },
        submitSuccess(d) {
            this.commitPopup?.closePopup(true);
            this.updateForm();
        },
        showPopup(e) {
            this.commitPopup?.showPopup(e);
            this.updateForm();
        },
    },
};
</script>
