<template>
  <div class="custom-breadcrumb-container" v-if="breadcrumbItems">
    <div v-for="item in breadcrumbItems" :key="item.name" class="link-container" :class="{ 'selected': item.selected }"
      @click="(e) => linkClick(item,e)">
      {{ item.title ?? "-" }}
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomBreadcrumb",
  props: {
    items: { type: Array,default: () => undefined },
  },
  data() {
    return {
      breadcrumbItems: undefined
    }
  },
  watch: {
    items() {
      this.manageRouteLinks();
    },
  },
  computed: {},
  mounted() {
    this.manageRouteLinks();
  },
  methods: {
    manageRouteLinks() {
      let tmp = this.items?.filter(x => {
        return x.url != null || x.routeData != null;
      })?.map((x,i) => {
        if (x.title == null) {
          x.title = "-"
        }
        if (x.name == null) {
          x.name = x.url ?? x.title ?? i.toString();
        }
        return x;
      }) ?? [];

      const lastSelected = tmp.filter(item => item === 2).find(x => x.selected == true) ?? [];
      const lastSelectedIndex = tmp.findIndex(item => item == lastSelected);
      if (lastSelected.length > 1) {
        tmp = tmp.map((x,i) => {
          if (i != lastSelectedIndex) {
            x.selected = false;
          }
          return x;
        })
      } else if (lastSelectedIndex == -1 && tmp.length > 0) {
        tmp[tmp.length - 1].selected = true;
      }

      this.breadcrumbItems = tmp;
    },
    linkClick(item,e) {
      if (item.selected != true) {
        if (e?.ctrlKey != true) {
          if (item.routeData) {
            this.$router.push(item.routeData);
            return
          } else if (item.url != null) {
            window.open(item.url);
            return
          }
        } else {
          let routeUrl = item.url;
          if (item.routeData != null) {
            routeUrl = this.$router.resolve(item.routeData).href;
          }
          window.open(routeUrl,'_blank','noopener,noreferrer');
          return;
        }
      }

    },
  },
};
</script>

<style lang="scss" scoped>
.custom-breadcrumb-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgba(0, 0, 0, .4);

  padding: .3rem .3rem;

  .link-container {
    padding: .2rem 0;
    max-width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: .7rem;
    white-space: nowrap;

    &:not(:last-child)::after {
      content: "/";
      margin-left: .2rem;
      margin-right: .2rem;
    }

    &:hover {
      color: rgba(var(--vs-primary), .8);
      cursor: pointer;
      &:not(.selected){
        font-size: .8rem;
      }
    }

    &.selected {
      padding: .3rem 0;
      color: rgba(var(--vs-primary), 1);
      font-size: .9rem;
    }
  }
}
</style>