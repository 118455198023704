<template>
  <div class="custom-tabs-container">
    <div class="tab-label-container" :class="labelClasses">
      <div v-for="item in filteredSlots" :key="item.name" class="tab-label" :class="{ 'selected': item.visible }"
        @click="changeSelectedItem(item.name)">
        {{ item.label }}
      </div>
    </div>
    <div class="tab-content-container">
      <div v-for="item in filteredSlots" :key="item.name">
        <component v-if="alwaysKeepLabel || item.visible" :is="item.node" :isActive="item.visible"
          :displayMode="displayMode"></component>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomTabs",
  props: {
    alignment: { type: String,default: () => "center" },
    modelValue: { type: String },
    displayMode: { type: String,default: () => "hidden" },
    manageSelectedTabByURL: { type: Boolean,default: () => false },
    name: { type: String,default: () => undefined },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      defaultSelected: undefined,
      selectedItem: undefined,
      alwaysKeepLabel: true,
      labelClasses: "",
      filteredSlots: [],
    }
  },
  watch: {
    $slots() {
      console.log("slot updated");
      this.updateFilteredSlots();
    },
    modelValue() {
      if (this.modelValue != this.selectedItem) {
        this.changeSelectedItem(this.modelValue);
      }
    },
    displayMode() {
      this.manageDisplayMode();
    }
  },
  computed: {
    urlSelector() {
      if (this.name) {
        return this.name;
      }
      return "selectedTab";
    }
  },
  mounted() {
    if (this.manageSelectedTabByURL) {
      this.defaultSelected = this.$route.query[this.urlSelector];
    }

    this.manageDisplayMode();
    this.updateFilteredSlots();
    this.updateLabelAligment();
  },
  methods: {
    manageDisplayMode() {
      if (this.displayMode == "hidden") {
        this.alwaysKeepLabel = true;
      } else {
        this.alwaysKeepLabel = false;
      }
    },
    changeSelectedItem(itemName) {
      this.selectedItem = itemName ?? this.defaultSelected;
      if (this.manageSelectedTabByURL) {
        if (this.selectedItem != this.defaultSelected) {
          const updatedQuery = { ...this.$route.query,[this.urlSelector]: this.selectedItem };
          this.$router.push({ query: updatedQuery });
          this.defaultSelected = this.selectedItem;
        }
      }
      this.$emit("update:modelValue",this.selectedItem);
      this.changeVisiblity();
    },
    changeVisiblity() {
      const visibleIndex = this.filteredSlots.findIndex(x => this.selectedItem == x.name);
      this.filteredSlots.forEach((x,index) => {
        if (visibleIndex != index) {
          x.visible = false;
        } else {
          x.visible = true;
        }
      });

      if (visibleIndex == -1 && this.filteredSlots.length > 0) {
        this.filteredSlots[0].visible = true;

        this.selectedItem = this.filteredSlots[0].name;
        this.$emit("update:modelValue",this.selectedItem);
      }
    },
    updateLabelAligment() {
      switch (this.alignment) {
        case "center":
          this.labelClasses = "centered-labels"
          break;
        case "left":
          this.labelClasses = "left-aligned-labels"
          break;
        case "right":
          this.labelClasses = "right-aligned-labels"
          break;
        case "space":
          this.labelClasses = "space-aligned-labels"
          break;
        default:
          break;
      }
    },
    updateFilteredSlots() {
      this.filteredSlots = this.filterSlots();
      this.$nextTick(() => {
        this.changeSelectedItem();
      });
    },
    filterSlots() {
      let slots = this.$slots.default();
      if (slots != null && slots.length == 1 && typeof slots[0].type == "symbol") {
        slots = slots[0].children;
      }
      return slots.filter((vnode) => {
        const componentName = vnode.type?.name ?? vnode.tag;
        return this.shouldRender(componentName);
      }).map((vnode) => {
        return {
          node: vnode,
          visible: false,
          label: vnode?.props?.label ?? '-',
          name: vnode?.props?.name ?? vnode?.props?.label ?? '-'
        };
      }) || [];
    },
    shouldRender(componentName) {
      const allowedComponents = ["CustomTab"];
      return allowedComponents.includes(componentName);
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-tabs-container {
  background-color: rgba(0, 0, 0, .04);
  border-radius: .5rem;
  overflow: hidden;

  .tab-content-container {
    background-color: rgba(0, 0, 0, .02);
    padding: .5rem;
  }

  .tab-label-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;

    &.centered-labels {
      justify-content: center;
    }

    &.left-aligned-labels {
      justify-content: left;
    }

    &.right-aligned-labels {
      justify-content: right;
    }

    &.space-aligned-labels {
      justify-content: space-around;
    }

    &>.tab-label {
      display: inline-flex;

      padding: .5rem .6rem;
      min-width: 4rem;
      text-align: center;
      font-size: .8rem;
      color: rgb(28, 30, 33);
      align-items: center;

      max-width: 15rem;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;

      &.selected {
        padding-bottom: 0.7rem;
        padding-top: 0.6rem;
        border-width: 0px 0px 2px;
        border-color: rgba(var(--vs-primary), 1);
        border-style: solid;
        color: rgba(var(--vs-primary), 1);

      }

      &:hover {
        cursor: pointer;
        color: rgba(var(--vs-primary), .9);
      }
    }
  }
}
</style>