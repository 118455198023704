import axios from "@/axios";
import { checkIdExist,storeDefaultModule } from "../../common";

const module = storeDefaultModule('/api/fb/pages',{
  actions: {
    getInfo({ dispatch },{ organisationId,id }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          url: `/api/fb/pages/${id}/info`,
          params: { organisationId },
        })
        .then((d) => {
          return d.data;
        });
    },
    postMessengerToken({ dispatch },{ organisationId,id,data }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          method: "POST",
          url: `/api/fb/pages/${id}/messengerToken`,
          params: { organisationId },
          data,
        })
        .then((d) => {
          return d.data;
        });
    },
    getSubscriptions({ dispatch },{ organisationId,id,force }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          url: `/api/fb/pages/${id}/subscriptions`,
          params: { organisationId,force },
        })
        .then((d) => {
          return d.data;
        });
    },
    getCustomLabels({ dispatch },{ organisationId,id }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          url: `/api/fb/pages/${id}/customLabels`,
          params: { organisationId },
        })
        .then((d) => {
          return d.data;
        });
    },
    postSubscriptions({ dispatch },{ organisationId,id,data }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          method: "POST",
          url: `/api/fb/pages/${id}/subscriptions`,
          params: { organisationId },
          data,
        })
        .then((d) => {
          return d.data;
        });
    },
    getIgUsers({ dispatch },{ organisationId,id }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          url: `/api/fb/pages/${id}/igusers`,
          params: { organisationId },
        })
        .then((d) => {
          return d.data;
        });
    },
    submitPost({ dispatch },{ organisationId,id,data }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          method: "POST",
          url: `/api/fb/pages/${id}/submitPost`,
          params: { organisationId },
          data,
        })
        .then((d) => {
          return d.data;
        });
    },
    getInstagramAccounts({ dispatch },{ organisationId,id }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          url: `/api/fb/pages/${id}/instagram/getAll`,
          params: { organisationId },
        })
        .then((d) => {
          return d.data;
        });
    },

    canManage({ rootState },id) {
      if (rootState?.auth?.stats?.canManage == true) {
        return true
      }
      if (id == null) {
        return false;
      }
      return rootState?.auth?.stats?.manageOrganisationIds?.find(x => x == id) != null;
    },
  },
  cacheKey: "FBPAGE"
});

export default module;
