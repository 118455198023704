<template>
    <CustomForm v-if="fields" :fields="fields" :initialValue="initVal" @formSubmit="(val,e) => submit(val,e)"
        :showSubmitButton="submitVisible">
        <template v-slot:fbtokenbuttons="{ data }">
            <div class="button-container" v-if="this.newData == true">
                <div
                    v-if="data.fbtokenbuttons?.canManage && data.fbtokenbuttons?.organisation != null && (data.appId != null || data.appId != '')">
                    <FbApplicationAvatar :applicationId="data.appId"
                        :organisationId="data.fbtokenbuttons.organisation._id" :showDetails="false" :showLink="true">
                    </FbApplicationAvatar>
                </div>
            </div>
        </template>
        <template v-slot:submitButton v-if="submitVisible">
            <CustomButton buttonClass="success-button" buttonType="submit" buttonText="general.submit"></CustomButton>
        </template>
    </CustomForm>
</template>

<script>
import FbApplicationAvatar from './FbApplicationAvatar.vue';
export default {
    name: "TokenEditComponent",
    props: {
        initialData: { type: Object,default: () => undefined },
        organisationId: { type: String },
        pageId: { type: String },
        applicationId: { type: String },
        manuelAdd: { type: Boolean,default: () => false },
        tokenType: { type: String,default: () => undefined },
        newData: { type: Boolean,default: () => false },
    },
    watch: {
        initialData() {
            this.updateForm();
        },
        pageId() {
            this.updateForm();
        },
        applicationId() {
            this.updateForm();
        },
        organisationId() {
            this.updateForm();
        }
    },
    components: {
        FbApplicationAvatar
    },
    computed: {
        authUser() {
            return this.$store.state.auth.user;
        },
    },
    data() {
        return {
            submitVisible: true,
            fields: [],
            initVal: {},
            name: undefined,
            token: undefined,
        };
    },
    mounted() {
        this.updateForm();
    },
    methods: {
        async getOrganisation() {
            return this.$store.dispatch(
                "organisation/getById",{
                id: this.organisationId,
            }).then(async d => {
                const canManage = await this.canManageOrganisation();
                return {
                    organisation: d,
                    canManage
                }
            });
        },
        canManageOrganisation() {
            return this.$store.dispatch(
                "organisation/canManage",
                this.organisationId
            ).then(d => {
                return d;
            });
        },
        async getAppOptions() {
            return this.$store
                .dispatch("fbApplication/getAll",{
                    organisationId: this.organisationId,
                })
                .then((data) => {
                    if (data.data) {
                        return data.data.map(x => {
                            return { label: x.name,val: x._id };
                        });
                    }
                    return [];
                }).catch(() => {
                    return [];
                })
        },
        async updateForm() {

            const tempFields = [
                {
                    type: "organisation-selector",
                    key: "organisationId",
                    label: "Organisation",
                    hidden: true,
                    order: 1,
                    changeAction: async (data,field,fields,additionalData,displayValue) => {
                        const appIdFieldIndex = fields.findIndex(x => x.key == "appId");

                        if (displayValue.tokenOrFbAuth == "FbLogin") {
                            let organisationId = this.organisationId;
                            if (organisationId == null && displayValue != null && displayValue.organisationId != null) {
                                organisationId = displayValue.organisationId;
                            }
                            const data = await this.getOrganisation(organisationId);
                            displayValue.fbtokenbuttons = data;
                            displayValue.organisationId = organisationId;

                            if (appIdFieldIndex > 0) {
                                fields[appIdFieldIndex].options = await this.getAppOptions(organisationId);
                            }
                        }
                        return [fields,displayValue];
                    }
                },
                {
                    type: "select",
                    key: "appId",
                    order: 2,
                    options: []
                },
                {
                    type: "radio",
                    key: "tokenOrFbAuth",
                    order: 2,
                    options: [
                        { label: "Manuel Login",val: "ManuelTokenLogin" },
                        { label: "Fb Login",val: "FbLogin" },
                    ],
                    changeAction: async (data,field,fields,additionalData,displayValue) => {
                        const tokenFieldIndex = fields.findIndex(x => x.key == "token");
                        const tokenTypeFieldIndex = fields.findIndex(x => x.key == "tokenType");

                        const buttonIndex = fields.findIndex(x => x.key == "fbtokenbuttons");

                        if (data == "FbLogin") {
                            let organisationId = this.organisationId;
                            if (organisationId == null && displayValue != null && displayValue.organisationId != null) {
                                organisationId = displayValue.organisationId;
                            }
                            const data = await this.getOrganisation(organisationId);
                            displayValue.fbtokenbuttons = data;
                            displayValue.organisationId = organisationId;

                            if (buttonIndex > -1) {
                                fields[buttonIndex].hidden = false;
                            }
                            if (tokenFieldIndex > -1) {
                                fields[tokenFieldIndex].hidden = true;
                                displayValue.token = undefined;
                            }
                            if (tokenTypeFieldIndex > -1) {
                                fields[tokenTypeFieldIndex].hidden = true;
                                displayValue.tokenType = undefined;
                            }
                            this.submitVisible = false;
                        } else if (data == "ManuelTokenLogin") {
                            if (buttonIndex > -1) {
                                fields[buttonIndex].hidden = true;
                            }
                            if (tokenFieldIndex > -1) {
                                fields[tokenFieldIndex].hidden = false;
                            }
                            if (tokenTypeFieldIndex > -1) {
                                fields[tokenTypeFieldIndex].hidden = false;
                            }
                            this.submitVisible = true;
                        }
                        return [fields,displayValue];
                    },
                },
                {
                    type: "fbtokenbuttons",
                    key: "fbtokenbuttons",
                    hidden: true,
                    order: 3,
                },
                {
                    type: "text",
                    key: "token",
                    label: this.$i18n?.t("model.token"),
                    hidden: true,
                    order: 3,
                },
                {
                    type: "select",
                    key: "tokenType",
                    label: this.$i18n?.t("model.basic-type"),
                    hidden: true,
                    order: 4,
                    options: [
                        { label: this.$i18n?.t("dbModel.tokenType-AUTHENTICATED_USER"),val: "AUTHENTICATED_USER" },
                        { label: this.$i18n?.t("dbModel.tokenType-AUTHENTICATED_PAGE_USER"),val: "AUTHENTICATED_PAGE_USER" },
                    ],
                }
            ];

            const tempVal = this.initialData ?? {};

            const organisationFieldIndex = tempFields.findIndex(x => x.key == "organisationId");
            const appIdFieldIndex = tempFields.findIndex(x => x.key == "appId");

            if (this.organisationId != null) {
                tempVal.organisationId = this.organisationId;
                if (organisationFieldIndex > -1) {
                    tempFields[organisationFieldIndex].hidden = true;
                }
                if (appIdFieldIndex > -1) {
                    tempFields[appIdFieldIndex].hidden = false;
                    tempFields[appIdFieldIndex].options = await this.getAppOptions(this.organisationId);
                }
            } else {
                if (organisationFieldIndex > -1) {
                    tempFields[organisationFieldIndex].hidden = false;
                }
            }

            this.fields = tempFields;
            this.initVal = tempVal;
        },
        submit(data) {
            if (this.manuelAdd) {
                return this.$store
                    .dispatch("fbAccessToken/token",{
                        ...data,
                        tokenType: this.tokenType,
                        organisationId: this.organisationId,
                        applicationId: this.applicationId,
                        pageId: this.pageId,
                    })
                    .then(() => {
                        this.$emit("submitData",data);
                    });
            }
            else {
                if (this.initialData?._id == null) {
                    return this.$store
                        .dispatch("fbAccessToken/add",{
                            ...(data ?? {}),
                            organisationId: this.organisationId,
                            applicationId: this.applicationId,
                            pageId: this.pageId,
                        })
                        .then(() => {
                            this.$emit("submitData",data);
                        });
                }
                else {
                    return this.$store
                        .dispatch("fbAccessToken/update",{
                            id: this.initialData._id,
                            data: {
                                ...(data ?? {}),
                                organisationId: this.organisationId,
                                pageId: this.pageId,
                                applicationId: this.applicationId,
                            }
                        })
                        .then(() => {
                            this.$emit("submitData",data);
                        });
                }
            }
        },
    },
};
</script>