import { createApp } from "vue";
import App from "./App.vue";
import Vuesax from "vuesax3";

import "vuesax3/dist/vuesax.css";
import "material-icons/iconfont/material-icons.css";

import router from "./router/index";
import { store } from "./store";
import i18nService from "./controllers/i18nService";

export const vuesax = Vuesax;

import FbCommentAvatarVue from "./components/facebook/FbCommentAvatar.vue";
import NotificationAvatarVue from "@/components/data/NotificationAvatar.vue";

import CustomTableVue from "./components/CustomTable.vue";
import CustomPopupVue from "./components/CustomPopup.vue";
import CustomAvatarVue from "./components/CustomAvatar.vue";
import CustomFormVue from "./components/CustomForm.vue";
import CustomDropdownVue from "./components/CustomDropdown.vue";
import CustomButtonVue from "./components/CustomButton.vue";
import CustomTooltipVue from "./components/CustomTooltip.vue";
import CustomFormInputGroupVue from './components/CustomFormInputGroup.vue';

import WaWebLabelAvatarVue from "./components/whatsappWeb/WaWebLabelAvatar.vue";
import FbLabelAvatarVue from "./components/facebook/FbLabelAvatar.vue";
import ConfirmationDialog from "./components/ConfirmationDialog.vue";

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import CustomTab from "./components/CustomTab.vue";
import CustomTabs from "./components/CustomTabs.vue";
import TokenEdit from "./components/facebook/TokenEdit.vue";
import CustomBreadcrumb from "./components/CustomBreadcrumb.vue";

const app = createApp(App);
app
  .use(vuesax)
  .use(store)
  .use(router)
  .use(i18nService.i18n);


app.component('FbCommentAvatar',FbCommentAvatarVue);
app.component('NotificationAvatar',NotificationAvatarVue);

app.component('CustomButton',CustomButtonVue);
app.component('CustomTable',CustomTableVue);
app.component('CustomPopup',CustomPopupVue);
app.component('CustomAvatar',CustomAvatarVue);
app.component('CustomForm',CustomFormVue);
app.component('CustomDropdown',CustomDropdownVue);
app.component('CustomTooltip',CustomTooltipVue);
app.component('CustomFormInputGroup',CustomFormInputGroupVue);
app.component('CustomTab',CustomTab);
app.component('CustomTabs',CustomTabs);
app.component('CustomBreadcrumb',CustomBreadcrumb);

app.component('WaWebLabelAvatar',WaWebLabelAvatarVue);
app.component('FbLabelAvatar',FbLabelAvatarVue);
app.component('ConfirmationDialog',ConfirmationDialog);
app.component('TokenEdit',TokenEdit);

app.component('VueDatePicker',VueDatePicker);

app.mount("#app");