<template>
  <select v-if="optionsValues" :value="modelValue" :disabled="disabled" @input="optionSelected">
    <option v-for="option in optionsValues" :key="option.val" :value="option.val"> {{ option.label }}
    </option>
  </select>
</template>

<script>

export default {
  name: "SocialContentLabelOptionSelectorCmp",
  props: {
    modelValue: { type: String,default: () => undefined },
    organisationId: { type: String },
    channelIds: { type: Array },
    disabled: { type: Boolean,default: () => false }
  },
  emits: ['update:modelValue','additionalData'],
  data() {
    return {
      optionsValues: undefined
    };
  },
  watch: {
    organisationId() {
      this.updateOptions();
    },
    channelIds() {
      this.updateOptions();
    },
  },
  computed: {},
  mounted() {
    this.updateOptions();
  },
  methods: {
    optionSelected(event) {
      const d = this.optionsValues.find(x => x.val == event.target.value);
      this.$emit('update:modelValue',event.target.value);
      this.$emit('additionalData',d.data);
    },
    updateOptions() {
      return this.$store.dispatch("socialContentLabelOption/getAll",{
        organisationId: this.organisationId,
        channelIds: this.channelIds,
        options: { sort: { name: 1 } },
      }).then(d => {
        if (d.data && d.data.length > 0) {
          this.optionsValues = d.data.map(x => {
            return { label: x.name,val: x._id,data: x };
          })
        } else {
          this.optionsValues = [];
        }
      });
    }
  },
};
</script>
<style lang="scss" scoped></style>