<template>
  <div class="conversation-list-container">
    <CustomTable ref="listComponent" :hideHeader="hideHeader" :displayReverse="displayReverse"
      :paginateScroll="paginateScroll" :enableAdd="enableAdd" @addReq="(e) => showMessagePopup(e)"
      :activeItem="activeItem" :headers="waWebSessionHeaders" :initialSort="initialSort" :sortOptions="sortOptions"
      :defaultFilter="defaultFilter" :filterOptions="filterOptions" :getDataRequest="getDataRequest"
      :lastUpdateWatchFunction="lastUpdateWatchFunction" :initialDataKey="initialDataKey">
      <template v-slot:avatar="{ data }">
        <WaWebChatAvatar :id="data.key" :organisationId="organisationId" :showDetails="true"
          :showPhoneInfo="showPhoneInfo" :navigateOnClick="navigateOnClick" :displaySimple="displaySimple"
          @click="$emit('itemClick',data.key)"></WaWebChatAvatar>
      </template>
    </CustomTable>
    <CustomPopup ref="messageForm" :showButton="false" :title="$t('general.sendMessageToUser')">
      <CustomForm :fields="fields" :initialValue="messageInitial" @formSubmit="(val,e) => submitMessage(val,e)">
        <template v-slot:topFormInfo>
          <CustomPopup ref="quickReplyPopup" :title="$t('general.selectTemplate')">
            <template v-slot:buttonContainer>
              <CustomButton buttonClass="input-option">
                <i class="fa-solid fa-envelope-open-text"></i>
              </CustomButton>
            </template>
            <CustomForm :fields="messageQuickReplyFields" @formSubmit="(val,e) => selectTemplateToMessage(val,e)">
              <template v-slot:submitButton>
                <CustomButton buttonClass="success-button" buttonType="submit" buttonText="general.select"></CustomButton>
              </template>
            </CustomForm>
          </CustomPopup>
        </template>
      </CustomForm>
    </CustomPopup>
  </div>
</template>
<script>
import WaWebChatAvatar from "@/components/whatsappWeb/WaWebChatAvatar.vue";
import CustomPopup from "../CustomPopup.vue";
import CustomForm from "../CustomForm.vue";
import { manageFormSubmitButtons } from "../../helpers/common";

export default {
  name: "WaWebChatListComponent",
  props: {
    organisationId: { type: String,default: () => null },
    waWebPhoneId: { type: String,default: () => null },
    waWebChatId: { type: String,default: () => null },
    waWebContactId: { type: String,default: () => null },
    waWebSessionId: { type: String,default: () => null },
    waWebAppId: { type: String,default: () => null },
    waWebLabelIds: { type: Array,default: () => null },
    hideHeader: { type: Boolean,default: () => false },
    defaultFilter: { type: Object,default: () => undefined },
    filterOptions: { type: Array,default: () => [] },
    enableAdd: { type: Boolean,default: () => false },
    showPhoneInfo: { type: Boolean,default: () => false },
    displayReverse: { type: Boolean,default: () => false },
    paginateScroll: { type: Boolean,default: () => false },
    navigateOnClick: { type: Boolean,default: () => true },
    displaySimple: { type: Boolean,default: () => false },
    activeItem: { type: String,default: () => null },
  },
  components: { WaWebChatAvatar,CustomPopup,CustomForm },
  emits: ['itemClick'],
  data() {
    return {
      initialDataKey: JSON.stringify({
        key: "waWebChat/getAll",params: {
          organisationId: this.organisationId,
          waWebPhoneId: this.waWebPhoneId,
          waWebChatId: this.waWebChatId,
          waWebContactId: this.waWebContactId,
          waWebSessionId: this.waWebSessionId,
          waWebLabelIds: this.waWebLabelIds,
        }
      }),
      waWebSessionHeaders: [{ key: "avatar",data: "Avatar" }],
      initialSort: { lastMessageDate: -1 },
      sortOptions: [
        { key: "lastMessageDate",text: this.$i18n?.t("model.basic-date") },
        { key: "name",text: this.$i18n?.t("model.basic-username") },
      ],
      getDataRequest: (tableData) => {
        if (this.parentId) {
          tableData.filter.parentId = this.parentId;
        }
        return this.$store.dispatch("waWebChat/getAll",{
          organisationId: this.organisationId,
          waWebPhoneId: this.waWebPhoneId,
          waWebChatId: this.waWebChatId,
          waWebContactId: this.waWebContactId,
          waWebSessionId: this.waWebSessionId,
          waWebLabelIds: this.waWebLabelIds,
          options: {
            ...tableData,
            projection: { _id: 1 },
          },
          force: tableData?.force,
        });
      },
      lastUpdateWatchFunction: (val) => {
        if (val && val.objectType && val.objectType.match(/^WAWEBCHAT_/)) {
          return true;
        }
        return false;
      },
      messageInitial: { message: "" },
      fields: [
        {
          type: "wawebphone-selector",
          key: "waWebPhoneId",
          label: this.$i18n?.t("model.dbModel-waWebPhoneId"),
          attributes: {
            selectIfOneItem: true
          }
        },
        {
          key: "phoneNumber",
          label: this.$i18n?.t("model.dbModel-phoneNumber"),
          placeholder: this.$i18n?.t('model.basic-number'),
        },
        {
          type: "textarea",
          key: "message",
          label: this.$i18n?.t("model.dbModel-message"),
          placeholder: "Type a message",
          autocomplete: "off",
          displayLabel: false,
        },
      ],
      attachmentData: undefined,
      messageQuickReplyFields: [{
        key: "templateId",
        label: "Template",
        type: "organisation-quick-reply-selector",
        dataFormatter: function (file,data) {
          return {
            mimetype: file.type,
            data,
            filename: file.name,
            filesize: file.size
          };
        }
      }],
    };
  },
  computed: {
    messageForm() {
      return this.$refs['messageForm'];
    }
  },
  methods: {
    selectTemplateToMessage(val,e) {
      manageFormSubmitButtons(e,() => {
        if (val.templateId == null) {
          return;
        }
        return this.$store
          .dispatch("organisationQuickReply/getById",{ id: val.templateId,mergeRequest: true })
          .then((d) => {
            if (d.isActive == true && d.deleted != true && d.template) {
              this.messageInitial = this.messageInitial ?? {};
              this.messageInitial.message += d.template;
              this.messageInitial = { ...this.messageInitial };
            }
          }).catch(() => null).then(d => {
            this.$refs.quickReplyPopup?.closePopup(true);
          });
      });
    },
    async submitMessage(val) {
      await this.$store
        .dispatch("waWebMessage/submit",{
          waWebPhoneId: val.waWebPhoneId,
          data: { ...val,attachments: this.attachmentData },
        })
        .then(() => {
          this.$store.dispatch("showNotification",{
            type: "success",
            title: this.$i18n?.t("messages.MESSAGE_SENT_SUCCESS"),
          });
          this.messageInitial = { message: "" };
        }).catch(() => null);
    },
    showMessagePopup(val) {
      if (this.messageForm?.showPopup) {
        this.messageForm?.showPopup();
      }
    },
  }
};
</script>