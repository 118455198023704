import axios from "@/axios";
import { checkIdExist,storeDefaultModule } from "../common";

const module = storeDefaultModule('/api/organisations/subscriptions',{
  actions: {
    relatedItems({ dispatch },{ id,organisationId }) {
      checkIdExist(id,{ dispatch });
      return axios
        .baseRequest({
          url: `/api/organisations/subscriptions/${id}/relatedItems`,
          params:{
            organisationId
          }
        },false)
        .then((d) => {
          return d.data;
        });
    },
  },
  cacheKey: "ORGANISATIONSUBSCRIPTION"
});

const clearCachedDataSuper = module.actions.clearCachedData;
module.actions.clearCachedData = ({ dispatch },{ item,key }) => {
  return clearCachedDataSuper({ dispatch },{ item,key });
}

export default module;