import axios from "@/axios";
import { checkIdExist, storeDefaultModule } from "../../common";

const module = storeDefaultModule("/api/fb/users", {
  actions: {
    getAccounts(
      { dispatch },
      {
        id,
        organisationId,
        pageId,
        projection,
        force,
        selectedPageIds,
        selectedIgUserIdPageIds,
      }
    ) {
      checkIdExist(id, { dispatch });
      return axios
        .baseRequest(
          {
            url: `/api/fb/users/${id}/accounts`,
            params: {
              organisationId,
              pageId,
              projection,
              selectedPageIds,
              selectedIgUserIdPageIds,
              force,
            },
          },
          !(force == true)
        )
        .then((d) => {
          return d.data;
        });
    },
    getCustomLabels(
      { dispatch },
      { id, organisationId, pageId, projection, force }
    ) {
      checkIdExist(id, { dispatch });
      return axios
        .baseRequest(
          {
            url: `/api/fb/users/${id}/customLabels`,
            params: {
              organisationId,
              pageId,
              projection,
              force,
            },
          },
          !(force == true)
        )
        .then((d) => {
          return d.data;
        });
    },
  },
  cacheKey: "FBUSER",
});

export default module;
